<template>
  <div class="rentPlaceOrder">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :isOperateColumn="false"
    >
      <template #headBtnSlot>
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <v-select
          clearable
          :options="areaList"
          v-model="searchParam.rentSpaceType"
          @change="$refs.list.search()"
          label="所属地区"
        />
        <v-input
          placeholder="请输入房号"
          label="房号"
          v-model="searchParam.spaceName"
          :width="250"
        ></v-input>
        <v-select
          clearable
          :options="areaCountList"
          v-model="searchParam.spaceSize"
          @change="$refs.list.search()"
          label="面积"
        />
        <v-select
          clearable
          :options="directionList"
          v-model="searchParam.direction"
          @change="$refs.list.search()"
          label="朝向"
        />
        <v-input
          placeholder="请输入用户名/手机号"
          label="用户名/手机号"
          v-model="searchParam.keyword"
          :width="250"
        ></v-input>
      </template>
      <template>
        <!-- <v-button text="编辑" type="text" @click="toEdit(scope.row)" /> -->
        <!-- <v-button text="删除" type="text" @click="toDelete(scope.row)" /> -->
      </template>
    </v-list>
  </div>
</template>

<script>
import { getSearchListUrl, getRentListUrl, getRentOrderUrl } from "./api.js";
import {
  classesMap,
  classesOps,
  gradeListMap,
  classes,
  gradeList,
} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "topicList",
  data() {
    return {
      directionList: [],
      areaCountList: [],
      areaList: [],
      rentStatusList: [],
      searchParam: {
        rentSpaceType: "",
        spaceName: "",
        spaceSize: "",
        direction: "",
        keyword: "",
      },
      teacherSignList: [],
      tableUrl: getRentOrderUrl,
      headers: [
        {
          prop: "typeName",
          label: "所属地区",
        },
        {
          prop: "picture",
          label: "图片",
          formatter: (row, prop) => createImgVNode(this, row, prop),
        },
        {
          prop: "spaceName",
          label: "房号",
        },

        {
          prop: "size",
          label: "面积",
        },
        {
          prop: "direction",
          label: "朝向",
        },
        {
          prop: "userName",
          label: "申请人",
        },
        {
          prop: "mobile",
          label: "申请人电话",
        },
        {
          prop: "companyName",
          label: "申请公司名称",
        },
        {
          prop: "companyProfile",
          label: "申请公司介绍",
        },
        {
          prop: "applyDate",
          label: "申请时间",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    this.getSearchInfo();
  },
  methods: {
    getSearchInfo() {
      let params = {};
      this.teacherSignList = [];
      this.$axios.get(`${getSearchListUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          if (res.data && res.data.directionList) {
            res.data.directionList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.directionList.push(item);
            });
          }
          if (res.data && res.data.rentStatusList) {
            res.data.rentStatusList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.rentStatusList.push(item);
            });
          }
          if (res.data && res.data.rentSpaceTypeList) {
            res.data.rentSpaceTypeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.areaList.push(item);
            });
          }
          if (res.data && res.data.roomSizeList) {
            res.data.roomSizeList.forEach((item) => {
              item.label = item.dictValue;
              item.value = item.dictKey;
              this.areaCountList.push(item);
            });
          }
        }
      });
    },

    toAdd() {
      this.$router.push({
        name: "rentPlaceDetail",
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "rentPlaceDetail",
        query: { id: row.spaceId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.rentPlaceOrder {
  box-sizing: border-box;
  height: 100%;
}
</style>
