let origin = "https://future.zhuneng.cn";
//租赁场所查询下拉列表
const getSearchListUrl = `/gateway/hc-space/space/getMsInitInfo`;
//租赁场所列表
const getRentListUrl = `/gateway/hc-space/space/getMsSpaceList`;
//租赁场所详情
const getRentDetailUrl = `/gateway/hc-space/space/detail`;
//新增租赁场所
const addRentUrl = `/gateway/hc-space/space/update`;
//租赁订单
const getRentOrderUrl = `/gateway/hc-space/space/getMsRentApplyList`;
export {
  getSearchListUrl,
  getRentListUrl,
  getRentDetailUrl,
  addRentUrl,
  getRentOrderUrl,
};
